.folder-name {
    margin: 0px;
    padding-top: 80px;
    padding-left: 60px;
}

.folder-name div {
    padding: 10px 8px;
}

.folder-name label {
    color: black;
    font-size: 14pt;
}

.r-table {
    padding: 0px 0px 0px 40px;
    margin-bottom: 30px;
    width: 100%;
    overflow-y: auto; 
    overflow-x: hidden;   
    max-height: 550px;
    display:inline-block;
}

.r-train a {
    color: rgb(2, 128, 0);
    font-weight: bold;
}

.r-stat a {
    color: rgb(21, 0, 128);
    font-weight: bold;
}

.r-table table a {
    margin-bottom: 0;
    font-size: 10pt;
}

.r-table table td {
    padding: 6px 12px;
}

.r-table .aa {
    padding: 0;
}

.r-table td {
    vertical-align: middle;
}

.r-table table th {
    padding: 8px 12px;
}


.r-sm {
    padding: 1px 8px;
    font-size: 9pt;
}

.f-sm {
    font-size: 10pt;
}

.f-sm2 {
    font-size: 9pt;
}


.cg {
    font-weight: bold;
    color: rgb(32, 100, 32);
}

.cy {
    font-weight: bold;
    color: rgb(133, 133, 27);
}

.cr {
    font-weight: bold;
    color: rgb(175, 62, 62);
}

.bc_ok {
    background-color: #BFFCC6;
}

.bc_al {
    background-color: #FFFFD1;
}

.bc_err {
    background-color: #FFB5E8;
}

.g-ann {
    background-color: #BFFCC6;
}

.g-reg {
    background-color: #d1f5cd;
}


.g-e {
    background-color: #f3f5cd;
}

.g-af {
    background-color: #f5d6cd;
}

.g-noise {
    background-color: #bfc7c0;
}

.g-saw {
    background-color: #c7bfc7;
}