.ecg-signal-panel {
    flex: 1 auto;
    position: relative;
    border: none;
    height: 344px;
    margin-bottom: 0px;
    background-image: url("paper.png");
    background-repeat: repeat;
    text-align: left;
    vertical-align: top;
}

.ecg-mv {
    position: absolute;
    left: 15px;
    top: 0px;
}

.ecg-signal {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
}

.ecg-ticks {
    position: absolute;
    left: 0px;
    top: 318px;
}

.ecg-ticks label {
    font-size: 7pt;
    color: #9a9a9b;
}

.ecg-ticks label i {
    font-size: 6pt;
    font-weight: none;
}

.ecg-beats {
    position: absolute;
    left: 0px;
    top: 2px;
}

.ecg-hr-mark, .ecg-rr-mark {
    font-size: 7pt;
    color: rgb(0,15,75);
    top: 8px;
}

.ecg-control {
    position: absolute;
    left: 0;
    top: 302px;  
    width: 100%;
}

.ecg-control .x-control {
    background-color: white;  
}

.ecg-control .x-control label {
    width: 29px;
    margin: 0;
}
