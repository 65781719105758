.a-top {
  display: none;
}

.hero_home .content {
  background-color: #fafafb;
  vertical-align: top;
  padding: 80px 36px 20px 20px;
}

.hero_home .content-record {
  background-color: #fafafb;
  vertical-align: top;
  padding: 0px 0px 0px 0px;
}

.margin-10-35 {
  padding-top: 10px;
  padding-left: 35px;
}

.pos-abs {
    position: absolute;
}

.x-centered {
   transform: translate(-50%, 0);
}
