.ecg-row-container {
    display: flex;
    flex-wrap: nowrap;
    margin-right: 5px;
}


.time-info-panel {
    min-width: 200px;
    background-image: linear-gradient(to right, white, white, white, white, white, white, white, white, white, rgb(245, 245, 245));
    vertical-align: middle;
    line-height: 40px;
    text-align: center;
}
    

.time-info-panel label {
    margin-bottom: 0;
    padding-top: 2px;
    font-size: 12pt;    
}  

.ecg-info-panel {
    min-width: 200px;
    margin-left: 0px;
    margin-bottom: 0px;
    border-color: white;
    border: 1px solid rgb(243, 245, 247);
    border-bottom-left-radius: 6px;
    background-image: linear-gradient(to right, white, white, white, white, white, white, white, white, white, rgb(245, 245, 245));
    color: rgb(0,15,75);
    min-height: 300px;
    flex-basis: 0;
    position: relative;
}

.ecg-top-info {
    margin: 4px 0px 10px 22px;
}

.ecg-par {
    display: flex;
    cursor: pointer;
}

span.par-title {
    color: rgb(0,15,75);
    font-size: 23pt;
    font-weight: bold;
}

span.par-value {
    margin-left: 2px;
    text-align: right;
    min-width: 52px;
    color: rgb(1,194,169);
    font-size: 23pt;
    font-weight: bold;
}

span.par-unit {
    margin-left: 2px;
    margin-top: 14px;
    color: rgb(0,15,75);
    font-size: 11pt;
   
}

.ecg-param {
    margin: 4px 1px 6px 23px;  
}

.ecg-param h5 {
    color: rgb(135,152,173);
    font-size: 13pt;
}

h5.param-title {
    margin-right: 4px;
}

h5.param-value {
    font-weight: bold;
    margin-right: 4px;
    min-width: 50px;
    text-align: right;
}

h5.param-unit {
    margin-top: 3px;
    font-size: 11pt;
}

.ecg-channel {
    margin: 106px 2px 6px 23px;  
}

.ecg-channel h2 {
    color: rgb(0,15,75);
    font-size: 20pt;    
}

.ecg-info-switch-marker {
    position: absolute;
    left: 172px;
    top: 12px;
    cursor: pointer;
}

.ecg-info-switch-time {
    position: absolute;
    left: 172px;
    top: 320px;
    cursor: pointer;
}

.ecg-amp-control {
    position: absolute;
    left: 10px; 
    top: 268px;
}

.ecg-speed-control {
    position: absolute;
    left: 10px; 
    top: 306px;
}

.ecg-marker-color {
    color: rgb(1, 194, 169);
}

.btn-group-sm2 .btn, .btn-sm2 {
    padding: 0.25rem 0.5rem;
    font-size: .76rem;
    line-height: 1.5;
    border-color:  rgb(242,244,246);
    color:   rgb(0,15,75);
    border-radius: 0.2rem;    
}

.btn-group-sm2 label:hover {
  background-color: rgb(0,15,75);
  color: white;
}

.x-btn-left, .x-btn-right {
  min-width: 32px;
}

.x-btn-info {
  min-width: 80px;  
}